import React, { Fragment } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import dynamic from "next/dynamic";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./style";
import Formik from "./../FormikInput";
import InputBox from "./../FormikInput/Input";
import CheckBox from "./../FormikInput/Checkbox";
import SignInFormValidationSchema from "./SignInValidationSchema";

const SocialLogin = dynamic(() => import("../SocialLogin"), {
  ssr: false,
});

function SignInForm({ handleSubmit, isLoading }) {
  const classes = useStyles();

  return (
    <Fragment>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <br />
      <Grid container xs={12}>
        <SocialLogin />
        <Divider light={false} className={classes.divider} />
      </Grid>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={SignInFormValidationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        <InputBox
          name="email"
          required
          label="Email Address"
          autoComplete="email"
        />
        <InputBox
          name="password"
          type="password"
          required
          autoComplete="current-password"
          label="Password"
        />
        <CheckBox name="remember" color="primary" label="Remember me" />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={28} /> : "Sign In"}
        </Button>
      </Formik>
    </Fragment>
  );
}

export default SignInForm;

import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import NextLink from "./../NextLink";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import dynamic from "next/dynamic";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FORGOT_PASSWORD, SIGN_UP } from "../../config/routes";
import useStyles from "./style";
import Formik from "./../FormikInput";
import InputBox from "./../FormikInput/Input";
import CheckBox from "./../FormikInput/Checkbox";
import SignInFormValidationSchema from "./SignInValidationSchema";
import SingInForm from "./SignInForm";

function SignIn({ handleSubmit, isLoading }) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <SingInForm handleSubmit={handleSubmit} isLoading={isLoading} />
        <Grid container>
          <Grid item xs>
            <NextLink href={FORGOT_PASSWORD}>
              <Link variant="body2">Forgot password?</Link>
            </NextLink>
          </Grid>
          <Grid item>
            <NextLink href={SIGN_UP}>
              <Link variant="body2">{"Don't have an account? Sign Up"}</Link>
            </NextLink>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default SignIn;

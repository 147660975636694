import React, { useEffect, cloneElement } from "react";
import { Formik, useFormikContext, Form } from "formik";


// https://react-hook-form.com/get-started#TypeScript

const getTouchedObj = (errors) => {
  const touched = {};
  Object.keys(errors).map((key) => {
    touched[key] = true;
  });

  return touched;
};

const HandleSubmitComponent = ({
  submitFormFlag,
  handleSubmitError,
  handleSubmit,
  handleChange,
}) => {
  const {
    values,
    submitForm,
    isValid,
    validateForm,
    setTouched,
  } = useFormikContext();

  useEffect(() => {
    validateForm().then((err) => {
      if (submitFormFlag) {
        setTouched(getTouchedObj(err), false);
      }

      if (submitFormFlag && isValid) {
        submitForm(values)
          .then(() => {
            handleSubmit(values);
          })
          .catch((err) => {
            console.error(err);
            handleSubmitError(false);
          });
      } else {
        console.error("Error at line 39");
        handleSubmitError(false);
      }
    });
  }, [submitFormFlag]);

  // Change Handler
  useEffect(() => {
    if (handleChange) {
      handleChange(values);
    }
  }, [values]);
  return null;
};

export default ({
  handleSubmit,
  handleSubmitError,
  submitForm,
  children,
  validationSchema,
  onSubmit = () => {},
  initialValues = {},
  handleChange,
  renderForm,
}) => {
  console.log(initialValues, "initialValues");
  return (
    <Formik
      initialValues={initialValues || {}}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          {renderForm ? renderForm(formik.values) : null}
          {children ? children : null}
          {handleSubmit && handleSubmitError ? (
            <HandleSubmitComponent
              handleSubmit={handleSubmit}
              submitFormFlag={submitForm}
              handleSubmitError={handleSubmitError}
              handleChange={handleChange}
            />
          ) : null}
        </Form>
      )}
    </Formik>
  );
};
